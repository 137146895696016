import { useMemo } from "react";
import { AppBar, Divider, IconButton, Stack, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import useAuthStore from "@stores/authStore";
import getMaskedChannel from "@utils/getMaskedChannel";
import { Destination } from "@models/api/apiResponses";
import skipifyEvents from "@services/skipifyEvents";
import { FlowTypes } from "@constants/skipifyEvent";

export interface HeaderProps {
  widthOverride?: string;

  onExit?(): void;
}

export default function Header({ onExit, widthOverride = "90%" }: HeaderProps) {
  const [email, phone, maskedChannel, destination, destinationAlternatives, user, maskedEmail] = useAuthStore(
    (state) => [
      state.email,
      state.phone,
      state.maskedChannel,
      state.destination,
      state.destinationAlternatives,
      state.rawUser,
      state.maskedEmail,
    ],
  );

  const maskedPhone = useMemo<string>(() => {
    // If we know a full user's phone
    if (!maskedChannel && phone && phone.length > 0) {
      return getMaskedChannel(phone.slice(-4), Destination.PHONE);
    }

    // We neither know a full phone nor challenge has been initialized, so we can't tell masked phone
    if (!maskedChannel) {
      return "";
    }

    if (destination === Destination.PHONE) {
      return getMaskedChannel(maskedChannel, destination);
    }

    // While not authenticated
    if (!user) {
      const phoneAlternative = destinationAlternatives?.find((a) => a.destination === Destination.PHONE);
      if (phoneAlternative) {
        return getMaskedChannel(phoneAlternative.maskedChannel, phoneAlternative.destination);
      }
      return "";
    }

    // When authenticated

    // Deprecated User format
    if ("phoneNumber" in user) {
      return getMaskedChannel(user.phoneNumber, Destination.PHONE);
    }

    for (const idt of user.identities) {
      if (idt.type === Destination.PHONE) {
        return getMaskedChannel(idt.value.slice(-4), Destination.PHONE);
      }
    }

    return "";
  }, [destination, destinationAlternatives, maskedChannel, phone, user]);

  return (
    <AppBar
      color="inherit"
      position="sticky"
      elevation={0}
      sx={{ px: 2, height: "53px", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Stack sx={{ height: "100%" }} direction="row" justifyContent="space-around" alignItems="center">
        <Stack
          sx={{ height: "100%", width: widthOverride }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {!email && !maskedEmail && !maskedPhone && (
            <Typography noWrap variant="xs" fontWeight="bold" width="auto">
              Contact Information
            </Typography>
          )}
          <Typography noWrap variant="xs" fontWeight="bold" width="auto">
            {email || maskedEmail}
          </Typography>
          &nbsp;
          <Typography variant="xs" fontWeight="regular" minWidth="fit-content">
            {maskedPhone}
          </Typography>
          &nbsp;&nbsp;
        </Stack>
        <IconButton
          size="medium"
          sx={{ backgroundColor: "#F3F3F3", marginRight: -1, color: "text.primary" }}
          onClick={onExit}
        >
          {
            // Curently (3/26/24) we only support the Skpify layer in the context of the merchant for EGC flow, where it is anchored to their email input.
            // In the cases where we want to show the v2 styles but not have the ifram anchored anywhere on the merchant site, we will use a X icon.
            skipifyEvents.flowType === FlowTypes.EGC ? (
              <ExpandLessIcon fontSize="small" />
            ) : (
              <CloseIcon fontSize="small" />
            )
          }
        </IconButton>
      </Stack>
      <Divider sx={{ mx: -2 }} />
    </AppBar>
  );
}
