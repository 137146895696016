import { Order, Shipping, UpdateOrderRequest, UpdateOrderRequestV2, V2Payment } from "@models/order";
import { isMatch } from "lodash";
import { AdjustablePrice, FeeUnit, OrderCartItem, TaxInfo } from "skipify-types";

export const getTotalItemsQuantity = (items: OrderCartItem[]) => {
  return items.reduce((itemsCount, item) => itemsCount + item.quantity, 0);
};

export const getPriceFromAdjustablePrice = (adjustablePrice?: AdjustablePrice, TaxInfo?: TaxInfo) => {
  if (!adjustablePrice) return 0;
  return Number(adjustablePrice.adjustedAmount?.value ?? adjustablePrice.amount.value) + Number(TaxInfo?.value ?? 0);
};

export const getSelectedShippingOption = <T extends Pick<Shipping, "options" | "selected">>({
  options,
  selected,
}: T) => {
  return options?.find((option) => option.id === selected);
};

/**
 * Validates that the requested update is actually a new state for the order.
 *
 * @param order Current state of the order
 * @param update Requested updated state of the order
 * @returns True if order exists and if any property on the update is different from the current state.
 */
export const validateOrderUpdate = (order: Order | undefined, update: UpdateOrderRequest): boolean => {
  if (!order) {
    return false;
  }

  // remove any properties from baseUpdate that have different locations on the reviewed order object.
  const { tip, rewardPoints, discountCode, ...baseUpdate } = update;

  // check the equality for non-baseUpdate properties above, early return if any do not match
  if (tip && (tip?.value !== order.pricing.tip?.value || tip?.uom !== order.pricing.tip?.uom)) {
    return true;
  }
  if (rewardPoints != null && rewardPoints !== order.rewardPoints) {
    // TODO: revisit if we change how rewards points are removed from orders
    return true;
  }

  // We allow discount code to be null since it's a way to remove it
  if ((discountCode || discountCode === null) && discountCode !== order.pricing.discount?.code) {
    // TODO: revisit if we change how discounts are removed from orders
    return true;
  }

  // an empty update isn't a valid update
  if (Object.keys(baseUpdate).length < 1) {
    return false;
  }

  // do a (potentially) more expensive compare for the rest of the order
  const match = isMatch(order, baseUpdate);
  return !match;
};

/**
 * Filters the merchat fees configs for a specific fee type
 *
 * @param fees The merchant fees configs
 * @param type The fee type name to filter for
 * @returns A filtered fees array
 */
export const getFee = (fees: FeeUnit[], type: string) => {
  return fees?.filter((item) => item.feeType === type);
};

// Helper function to determine if a request is of type UpdateOrderRequestV2
function isUpdateOrderRequestV2(request: UpdateOrderRequest | UpdateOrderRequestV2): request is UpdateOrderRequestV2 {
  return "payments" in request && "tip" in request && "discountCode" in request && "rewardPoints" in request;
}

// Function to map from UpdateOrderRequest to UpdateOrderRequestV2 or return V2 if it's already V2
export function mapUpdateOrderRequestToV2(request: UpdateOrderRequest | UpdateOrderRequestV2): UpdateOrderRequestV2 {
  // Check if input is already a UpdateOrderRequestV2
  if (isUpdateOrderRequestV2(request)) {
    return request;
  }

  const payments: V2Payment[] = [];

  // Map existing payment and billing information
  if (request.payment) {
    const v2Payment: V2Payment = {
      ref: request.payment.ref,
      type: request?.payment?.type,
    };
    if (request.billing) {
      v2Payment.billing = { ref: request.billing.ref };
    }
    payments.push(v2Payment);
  }

  // Create the new request with potential additional fields
  const v2Request: UpdateOrderRequestV2 = {
    payments: payments.length > 0 ? payments : undefined,
    tip: request.tip,
    discountCode: request.discountCode,
    rewardPoints: request.rewardPoints,
    shipping: request.shipping,
    items: request.items,
  };

  return v2Request;
}
