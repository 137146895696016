import { OrderPayment, OrderPricing, OrderShipping, ShippingOption, Unit } from "skipify-types";
import { BasePublicOrder } from "skipify-types/dist/public/order";
import { ConsolidatedPaymentMethod } from "./card";
import { Address } from "@models/address";

export type ShippingOptionWithDates = ShippingOption & {
  maxDeliveryDateTime?: string;
  minDeliveryDateTime?: string;
};

export type Shipping = Omit<OrderShipping, "options"> & {
  options?: ShippingOptionWithDates[];
};

export type Order = Omit<BasePublicOrder, "shipping"> & {
  pricing: OrderPricing;
  shipping?: Shipping;
  rewardPoints?: number;
  memo?: string;
  description?: string;
  payment?: OrderPayment;
  payments?: CompleteOrderPaymentV2[];
};

type UpdateOrderItem = {
  id: string;
  quantity: number;
};

export enum PaymentType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  UNKNOWN = "UNKNOWN",
}

export type OrderPaymentType = keyof typeof PaymentType;

export type UpdateOrderRequest = {
  shipping?: {
    ref?: string;
    selected?: string;
  };
  billing?: {
    ref?: string;
  };
  payment?: {
    ref?: string;
    type?: OrderPaymentType;
  };
  items?: UpdateOrderItem[];
  tip?: Unit;
  //Not yet implemented
  discountCode?: string | null;
  rewardPoints?: number;
};

export type V2Payment = {
  type?: OrderPaymentType;
  ref?: string;
  billing?: {
    ref?: string;
  };
};

export type UpdateOrderRequestV2 = {
  payments?: V2Payment[];
  items?: UpdateOrderItem[];
  tip?: Unit;
  shipping?: {
    ref?: string;
    selected?: string;
  };
  //Not yet implemented
  discountCode?: string | null;
  rewardPoints?: number;
};

export type CompleteOrderRequest = {
  signature: string;
};

export type CompleteOrderPaymentV2 = {
  amount: number | bigint;
  ref: string;
};

export type CompleteOrderRequestV2 = {
  signature: string;
  payments: CompleteOrderPaymentV2[];
};

export type CompleteOrderResponse = {
  pspTransactionId: string;
  pspRawResponse: string;
  transactionAmount: number;
};

export type OrderTransactionDetails = {
  gatewayTransactionId: string;
  gatewayResponse: string;
};

export type CreateOrderRequest = unknown[];

export enum OrderCompleteErrors {
  CUSTOMER_ERROR = "CustomerError",
  MERCHANT_ERROR = "MerchantError",
  BANK_ERROR = "BankError",
  SKIPIFY_ERROR = "SkipifyError",
  GATEWAY_PSP_ERROR = "GatewayPspError",
  NETWORK_ERROR = "NetworkError",
}

export type OrderCompleteCallbackOptions = {
  enroll?: boolean;
  payment?: ConsolidatedPaymentMethod;
  billingAddressId?: string;
  transactionDetails: Array<CompleteOrderResponse>;
  tempBillingAddress?: Address;
};

export type CompletedPricing = {
  shipping?: Unit;
  tax?: Unit;
  tip?: Unit;
  total: Unit;
};

/**
 * An item that was purchased from a completed order
 */
export type CompletedOrderItem = {
  title: string;
  quantity: number;
  image?: {
    url: string;
    alt?: string;
  };
};

/**
 * Fulfillment information for a completed order
 */
export type CompletedOrderFulfillment = {
  /**
   * Internal status to Shopify that determines if tracking field in CompletedOrderFulfillment is populated.
   * For example, if status is pending then tracking has not been provided yet
   */
  status: "pending" | "open" | "success" | "cancelled" | "error";
  tracking?: {
    /**
     * The tracking status of a completed order. This is based off of Shopify's API
     */
    status: "confirmed" | "in_transit" | "out_for_delivery" | "delivered";
    id: string;
    carrier: string;
    url?: string;
  };
};

/**
 * A completed order
 */
export type CompletedOrderResponse = {
  id: string;
  merchantName: string;
  items: CompletedOrderItem[];
  shipping?: Shipping;
  pricing: CompletedPricing;
  orderCompleted: string;
  fulfillment?: CompletedOrderFulfillment;
};

/**
 * Response from OMS for completed orders
 */
export type GetCompletedOrdersResponse = {
  orders: CompletedOrderResponse[];
  nextPage?: string;
};
