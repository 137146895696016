import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { OrderCartItem } from "skipify-types";
import displayPrice from "@utils/displayPrice";
import { getPriceFromAdjustablePrice } from "@utils/orderUtils";
import { Order } from "@models/order";
import { useState } from "react";
import Form from "@components/inputs/Form";
import useOrderStore from "@stores/orderStore";

export type ProductProps = {
  item: OrderCartItem;
  nonHeaderDisplay?: boolean;
  order: Order;
  quantityEditable?: boolean;
  showRemove?: boolean;
};

const imageSize = 72;

export default function Product({
  item,
  nonHeaderDisplay,
  order,
  quantityEditable = false,
  showRemove = false,
}: ProductProps) {
  const { updateReview, deleteOrderItem, review } = useOrderStore();
  const flags = useFlags();

  const deleteItemErrorMessage = "There was an error when trying to remove selected item.";
  const quantityUpdateErrorMessage = "The amount of items selected are not in stock. Please select a different amount.";
  const [showItemChangeError, setShowItemChangeError] = useState(false);
  const [itemChangeError, setItemChangeError] = useState("");

  const [processing, setProcessing] = useState(false);
  const [displayQuantityInput, setDisplayQuantityInput] = useState(
    item.quantity >= 10 && item.inventoryAmount && item.inventoryAmount > 10,
  );
  const [customQuantity, setCustomQuantity] = useState(item.quantity);

  const [fallbackImageSrc, setFallbackImageSrc] = useState("");

  function getRemoveDisplay() {
    if (order.items.length === 1 || !showRemove || !quantityEditable || (displayQuantityInput && processing)) {
      return <></>;
    }
    if (processing) {
      return <CircularProgress size={14} color="secondary" />;
    } else {
      return (
        <Link
          role="button"
          fontSize={12}
          onClick={() => removeOrderItem(order.id, item.id)}
          variant="button"
          data-testid="remove-item-button"
        >
          Remove
        </Link>
      );
    }
  }

  function updateQuantity(orderId: string, itemId: string, quantity: number) {
    updateReview(orderId, { items: [{ id: itemId, quantity: quantity }] }, flags.useSplitPayments)
      .then(() => {
        setShowItemChangeError(false);
      })
      .catch(() => {
        setItemChangeError(quantityUpdateErrorMessage);
        setShowItemChangeError(true);
      })
      .finally(() => setProcessing(false));
  }
  function removeOrderItem(orderId: string, itemId: string) {
    if (processing) {
      return;
    }
    setProcessing(true);
    deleteOrderItem(orderId, itemId)
      .then(() => {
        setShowItemChangeError(false);
        review(orderId);
      })
      .catch(() => {
        setItemChangeError(deleteItemErrorMessage);
        setShowItemChangeError(true);
      })
      .finally(() => setProcessing(false));
  }
  function updateQuantityOnOrder(quantity: number) {
    if (processing) {
      return;
    }
    if (item.inventoryAmount && quantity > item.inventoryAmount) {
      setItemChangeError(quantityUpdateErrorMessage);
      setShowItemChangeError(true);
      return;
    }
    setProcessing(true);
    // update order or display input box
    // if 10+ is selected replace dropdown with input box
    if (quantity >= 10 && item.inventoryAmount && item?.inventoryAmount > 10) {
      setDisplayQuantityInput(true);
      setCustomQuantity(quantity);
      updateQuantity(order.id, item.id, quantity);
      return;
    }
    updateQuantity(order.id, item.id, quantity);
  }

  function quantityAdjustmentSection(quantity: number) {
    return [...Array(quantity > 10 ? 10 : quantity)]
      .map((_, i) => i + 1)
      .map((i) => (
        <MenuItem key={i} value={i}>
          {i == 10 && item.inventoryAmount && item.inventoryAmount > 10 ? "10+" : i}
        </MenuItem>
      ));
  }

  return (
    <Box data-testid="product-item">
      <Grid container spacing={nonHeaderDisplay ? 9 : 2}>
        {item.image && (
          <Grid
            item
            xs={nonHeaderDisplay ? 2 : "auto"}
            sm={nonHeaderDisplay ? 1.5 : "auto"}
            md={nonHeaderDisplay ? 3.2 : "auto"}
            lg={nonHeaderDisplay ? 3.3 : "auto"}
          >
            <Avatar
              variant="square"
              src={fallbackImageSrc || item.image?.url}
              alt={item.image?.alt}
              sx={{ height: imageSize, width: imageSize }}
              imgProps={{
                onError: () => setFallbackImageSrc("/static/no-image.svg"),
              }}
            />
          </Grid>
        )}
        <Grid item container xs spacing={2}>
          <Grid item xs container direction="column">
            {item.vendor && (
              <Typography variant="small" color="text.secondary">
                {item.vendor}
              </Typography>
            )}
            <Typography variant="medium">{item.title}</Typography>
            {item.inventoryAmount && quantityEditable ? (
              <Box sx={{ mb: 2 }}>
                <Box minWidth={"40%"}>
                  {!displayQuantityInput ? (
                    <Box>
                      <Select
                        error={showItemChangeError}
                        disabled={processing}
                        defaultValue={item.quantity}
                        sx={{ width: "40%", height: "42px", mt: 1, mr: 2 }}
                        onChange={(e) =>
                          updateQuantityOnOrder(
                            typeof e.target.value === "number" ? e.target.value : parseInt(e.target.value),
                          )
                        }
                        data-testid="quantity-dropdown-select"
                      >
                        {quantityAdjustmentSection(item.inventoryAmount)}
                      </Select>
                      {getRemoveDisplay()}
                    </Box>
                  ) : (
                    <Box sx={{ mt: 1 }}>
                      <Form onSubmit={() => updateQuantityOnOrder(customQuantity)}>
                        <TextField
                          error={showItemChangeError}
                          inputProps={{
                            style: { height: "42px", boxSizing: "inherit" },
                          }}
                          disabled={processing}
                          defaultValue={item.quantity > 10 ? item.quantity : 10}
                          type="tel"
                          sx={{ width: "40%", mr: 2 }}
                          value={customQuantity}
                          onChange={(e) => setCustomQuantity(e.target.value === "" ? 0 : parseInt(e.target.value))}
                          data-testid="quantity-input-field"
                        ></TextField>
                        <Button
                          disabled={customQuantity < 1 || processing || customQuantity === item.quantity}
                          type="submit"
                          sx={{ width: "40%", height: "42px", mr: 2 }}
                          variant="contained"
                          data-testid="update-quantity-button"
                        >
                          {processing ? <CircularProgress size={24} color="secondary" /> : "Update"}
                        </Button>
                        {getRemoveDisplay()}
                      </Form>
                    </Box>
                  )}
                </Box>
                {showItemChangeError && (
                  <Typography color={"error.main"} variant="small" lineHeight="16px" fontWeight="bold">
                    {itemChangeError}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <Box>{getRemoveDisplay()}</Box>
                <Typography variant="small" color="text.secondary">
                  Quantity: {item.quantity}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item sx={{ textAlign: "right" }} maxWidth="50%">
            <>
              <Typography variant="medium" fontWeight="bold">
                {displayPrice(getPriceFromAdjustablePrice(item.pricing, item.pricing.tax))}
              </Typography>
              {!!item.pricing.priceDifference?.value && (
                <Typography variant="medium" fontWeight="bold">
                  (
                  <Typography
                    component="span"
                    variant="medium"
                    fontWeight="bold"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {displayPrice(item.pricing.amount.value)}
                  </Typography>
                  )
                </Typography>
              )}
            </>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
