import { PaylinkStatus } from "skipify-types";

import { Address } from "@models/address";
import { ConsolidatedPaymentMethod } from "@models/card";
import { MerchantConfig } from "@models/merchantConfig";
import { Order } from "@models/order";
import { ShippingOption } from "@models/shippingOption";
import { User } from "@models/user";
import { XOR } from "@models/xor";
import { AuthServiceUser } from "@models/authServiceUser";

//do not confuse with AxiosResponse, which also has code, data and message
//this is json payload from core backend server
export type ApiResponse<T> = {
  /** HTTP status code */
  code: number;
  /** API payload */
  data?: T;
  /** Descriptive message for the status code */
  message: string;
};

export type OrdersResponse = {
  order: Order;
  payment_method: ConsolidatedPaymentMethod;
  shipping_address: Address;
};

export type UpdateOrderResponse = {
  order_id: number;
};

export type LookupUserResponse = {
  transactionId: string;
  isPhoneRequired?: boolean;
  phonePrefilled?: boolean;
  eligible?: boolean;
  destination?: string;
  maskedChannel?: string;
  maskedEmail?: string;
  maskedPhone?: string;
  errorStatus?: LookupUserErrorStatus;
};

export type LookupUserFullResponse = Pick<LookupUserResponse, "transactionId" | "eligible">;

export type LookupUserErrorResponse = {
  errorStatus: LookupUserErrorStatus;
};

export enum LookupUserErrorStatus {
  PHONE_LIMIT_REACHED = "PHONE_LIMIT_REACHED",
}

export type GuestResponse = {
  jwt: string;
};

export enum StepupType {
  OTP = "OTP",
  PUSH = "PUSH",
}

export enum Destination {
  EMAIL = "email",
  PHONE = "phone",
  WEBAUTHN = "webauthn",
}

export type DestinationAlternative = {
  maskedChannel: string;
  destination: Destination;
  id: string;
};

export type AuthorizeResponse = {
  message: string;
  stepup_type: StepupType;
  maskedChannel?: string;
  destination: string;
  transactionId: string;
  metadata?: object;
  destinationAlternatives?: DestinationAlternative[];
};

export type VerifyResponse = {
  authenticated: boolean;
  message: string;
  session?: string;
  // New auth service primary response
  jwt?: string;
};

export type UserResponse = User;

export type AuthServiceUserResponse = AuthServiceUser;

export type ListAddressResponse = Address[];

export type AddressResponse = Address;

export type CreateAddressResponse = string;

export type UpdateAddressResponse = string;

export type ShippingOptionResponse = ShippingOption[];

export type ListPaymentMethodsResponse = ConsolidatedPaymentMethod[];

export type PaymentMethodsResponse = ConsolidatedPaymentMethod;

//TODO -- fix response types
export type AllCardsMethodsResponse = { cards: ConsolidatedPaymentMethod[] };

export type DeliveryOptionResponse = ConsolidatedPaymentMethod[];

export type ShippingMethodResponse = {
  // TODO: subject to change as the ecommerce API changes
  availableShippingRates: ShippingOption[];
  subtotalPrice: string;
  taxPercentage: number;
  totalPrice: string;
  totalShippingPrice: string;
  totalTax: string;
};

export type CreatePaymentMethodResponse = {
  id: string;
};

export type UpdatePaymentMethodResponse = {
  id: string;
};

export type MerchantResponse = MerchantConfig;

export type CardLinkingData = {
  id: string;
  user_id: string;
  issuer_id: string;
  card_metadata_id: string;
  status: string;
  delinked: boolean;
  is_default: boolean;
  billing_address_id: string;
  cvv_verified: boolean;
  card_linked: boolean;
  card_group_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
};

export type RedirectResponse = {
  redirect_issuer_url: string;
};

export type ConfirmCardResponse = XOR<CardLinkingData, RedirectResponse>;

export type CompleteCardResponse = CardLinkingData;

export interface ProcessPaylinkResponse {
  status: PaylinkStatus;
  orderId: string;
  merchantId: string;
}
