import { Order } from "@models/order";
import { Box, Grid, Stack } from "@mui/material";
import useFeatureFlags from "@stores/featureFlags";
import { getFee, getPriceFromAdjustablePrice, getTotalItemsQuantity } from "@utils/orderUtils";
import { FeeUnit } from "skipify-types";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import { useFlags } from "launchdarkly-react-client-sdk";
import OrderLine from "./OrderLine";

type Props = {
  order: Order;
};

export default function OrderTotal({ order }: Props) {
  const flags = useFeatureFlags();
  const ldFlags = useFlags();
  const storeConfigs = useMerchantConfigStore();
  const discountEnabled = storeConfigs.cartConfigs.DISCOUNTS.enabled;

  // show discount line if:
  // 1. discount is enabled 2. discount is disabled but discount amount is not 0
  const showDiscountLine = Boolean(discountEnabled || order.pricing.discount?.amount?.value);
  const generalFees = getFee(order.pricing.fees as FeeUnit[], "general");
  const surchargeFee = getFee(order.pricing.fees as FeeUnit[], "surcharge")[0];
  const surchargeFeeBaseText =
    "Note: Surcharge fees on your order may change depending on the card used for your purchase";

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={1} direction="column">
        <OrderLine
          label={`Subtotal (${getTotalItemsQuantity(order.items)} items)`}
          lineTotal={getPriceFromAdjustablePrice(order.pricing.subTotal, order.pricing.subTotal.tax)}
          currency={order.pricing.subTotal.amount.uom}
          typography="medium"
        />
        {ldFlags.tipping && order.merchant.tippingEnabled && (
          <OrderLine
            label="Tip"
            lineTotal={order.pricing.tip?.value || 0}
            currency={order.pricing.tip?.uom}
            typography="medium"
          />
        )}
        {flags.generalFee &&
          storeConfigs.feeConfigs?.GENERAL_FEE.enabled &&
          generalFees.length > 0 &&
          generalFees.map((fee, idx) => {
            return (
              <OrderLine
                key={idx}
                label="Convenience Fee"
                lineTotal={fee?.value || 0}
                currency={fee?.uom || "USD"}
                typography="medium"
                info={storeConfigs.feeConfigs?.GENERAL_FEE.description}
              />
            );
          })}
        {flags.surchargeFee && storeConfigs.feeConfigs?.SURCHARGE_FEE.enabled && (
          <OrderLine
            label="Surcharge Fee"
            lineTotal={surchargeFee?.value || 0}
            currency={surchargeFee?.uom || "USD"}
            typography="medium"
            info={
              <Stack>
                <div>{storeConfigs.feeConfigs?.SURCHARGE_FEE.description}</div>
                <div>{surchargeFeeBaseText}</div>
              </Stack>
            }
          />
        )}
        {order.pricing.tax?.value !== undefined && order.pricing.tax?.value !== null && (
          <OrderLine
            label="Tax"
            lineTotal={order.pricing.tax?.value || 0}
            currency={order.pricing.tax?.uom}
            typography="medium"
          />
        )}
        {order.pricing?.shipping?.value !== undefined && (
          <OrderLine
            label="Shipping"
            lineTotal={order.pricing?.shipping?.value}
            displayZeroAsFree
            typography="medium"
          />
        )}
        {showDiscountLine && (
          <OrderLine
            label="Discount"
            lineTotal={order.pricing.discount?.amount?.value ?? 0}
            currency={order.pricing.discount?.amount?.uom ?? order.pricing.total?.uom}
            typography="medium"
            /* discounts should always be negative so if a positive value comes from OS, display value as negative */
            displayNegative={(order.pricing.discount?.amount?.value ?? 0) > 0}
          />
        )}
        {order.pricing.total?.value !== undefined && (
          <OrderLine
            label="Total"
            lineTotal={order.pricing.total?.value}
            currency={order.pricing.total?.uom}
            typography="xl"
            bold={true}
          />
        )}
      </Grid>
    </Box>
  );
}
